import React from 'react';
import type { FC } from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn, PriceFormatterFn } from 'root/clientTypes';
import { Tabs } from 'wix-ui-tpa';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes } from './Widget.st.css';

export type ControllerProps = {
  menus: PopulatedMenu[];
  priceFormatter: PriceFormatterFn;
  navigateTo: NavigateToFn;
};

export const Widget: FC<WidgetProps<ControllerProps>> = ({ menus, priceFormatter, navigateTo }) => {
  const { activeTab, onClickTab, menusItems, shouldShowTabs } = useMenusLogic({ menus, navigateTo });

  return (
    <AppWrapper priceFormatter={priceFormatter}>
      {shouldShowTabs && <Tabs activeTabIndex={activeTab} items={menusItems} onTabClick={onClickTab} />}
      <div className={classes.root} data-hook={dataHooks.menus.container}>
        <Menu menu={menus[activeTab]} />
      </div>
    </AppWrapper>
  );
};

import React from 'react';
import type { PopulatedMenu } from 'root/apiTypes';
import { classes } from './Menu.st.css';
import { dataHooks } from 'root/dataHooks';
import { Sections } from 'root/components/Menus/Sections/Sections';
import { Text, TextTypography, Divider } from 'wix-ui-tpa/cssVars';
import { useMenuLogic } from './Menu.logic';

export type MenuProps = {
  menu: PopulatedMenu;
};

export const Menu = ({ menu }: MenuProps) => {
  const { showMenuName, showMenuDescription } = useMenuLogic();
  return (
    <div className={classes.menuContainer} data-hook={dataHooks.menu.container}>
      {showMenuName && (
        <Text className={classes.menuTitle} data-hook={dataHooks.menu.name} typography={TextTypography.largeTitle}>
          {menu.name}
        </Text>
      )}
      {showMenuDescription && (
        <Text
          className={classes.menuDescription}
          data-hook={dataHooks.menu.description}
          typography={TextTypography.largeTitle}
        >
          {menu.description}
        </Text>
      )}
      <Divider className={classes.divider} />
      <Sections sections={menu.sections} />
    </div>
  );
};

import { useContext } from 'react';
import { AppContext } from 'root/contexts/AppContext';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';

export const useItemLogic = ({ item }: { item: ShowcasePopulatedItem }) => {
  const { priceFormatter } = useContext(AppContext);
  const { showItemDescription, showItemPrice, showItemCurrency, showItemLabels, showItemImage, showItemsDivider } =
    useGetDisplayStylesParams();

  const hasPriceVariants = (item.priceVariants?.variants ?? []).length > 0;

  const price = item.priceInfo?.price ?? '';

  const priceContent = priceFormatter ? priceFormatter({ price, shouldDisplayCurrency: showItemCurrency }) : price;

  return {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    showItemPrice,
    showItemLabels,
    showItemImage,
    showItemsDivider,
  };
};

import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { Item } from 'root/components/Menus/Item/Item';
import { classes } from './Items.st.css';
import { dataHooks } from 'root/dataHooks';

export const Items = ({ items }: { items: ShowcasePopulatedItem[] }) => {
  return (
    <div className={classes.itemsContainer} data-hook={dataHooks.items.container}>
      {items.map((item) => (
        <Item key={item.id} item={item} />
      ))}
    </div>
  );
};

import { StyleParamType, wixColorParam, createStylesParams, wixFontParam } from '@wix/tpa-settings';
import type { StylesParamsType } from 'root/components/Menus/stylesParams';

type IMenusStylesParams = {
  menuBackgroundColor: StyleParamType.Color;
  // menuBackgroundShadow: StyleParamType.Color;
  menuBorderColor: StyleParamType.Color;
  menuBorderWidth: StyleParamType.Number;
  menuBorderRadiusTopRight: StyleParamType.Number;
  menuBorderRadiusTopLeft: StyleParamType.Number;
  menuBorderRadiusBottomRight: StyleParamType.Number;
  menuBorderRadiusBottomLeft: StyleParamType.Number;
  menuTitleTheme: StyleParamType.Font;
  menuTitleColor: StyleParamType.Color;
  menuDescriptionTheme: StyleParamType.Font;
  menuDescriptionColor: StyleParamType.Color;
};

const menusStylesParams = createStylesParams<IMenusStylesParams>({
  menuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // menuBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  menuBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  menuTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  menuDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

type ISectionsStylesParams = {
  sectionBackgroundColor: StyleParamType.Color;
  // sectionBackgroundShadow: StyleParamType.Color;
  sectionBorderColor: StyleParamType.Color;
  sectionBorderWidth: StyleParamType.Number;
  sectionBorderRadiusTopRight: StyleParamType.Number;
  sectionBorderRadiusTopLeft: StyleParamType.Number;
  sectionBorderRadiusBottomRight: StyleParamType.Number;
  sectionBorderRadiusBottomLeft: StyleParamType.Number;
  sectionTitleTheme: StyleParamType.Font;
  sectionTitleColor: StyleParamType.Color;
  sectionDescriptionTheme: StyleParamType.Font;
  sectionDescriptionColor: StyleParamType.Color;
};

const sectionsStylesParams = createStylesParams<ISectionsStylesParams>({
  sectionBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // sectionBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  sectionBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 24,
    }),
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sectionDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

type IItemsStylesParams = {
  itemBackgroundColor: StyleParamType.Color;
  // itemBackgroundShadow: StyleParamType.Color;
  itemBorderColor: StyleParamType.Color;
  itemBorderWidth: StyleParamType.Number;
  itemBorderRadiusTopRight: StyleParamType.Number;
  itemBorderRadiusTopLeft: StyleParamType.Number;
  itemBorderRadiusBottomRight: StyleParamType.Number;
  itemBorderRadiusBottomLeft: StyleParamType.Number;
  itemTitleTheme: StyleParamType.Font;
  itemTitleColor: StyleParamType.Color;
  itemDescriptionTheme: StyleParamType.Font;
  itemDescriptionColor: StyleParamType.Color;
  itemPriceTheme: StyleParamType.Font;
  itemPriceColor: StyleParamType.Color;
  itemImageRadiusTopRight: StyleParamType.Number;
  itemImageRadiusTopLeft: StyleParamType.Number;
  itemImageRadiusBottomRight: StyleParamType.Number;
  itemImageRadiusBottomLeft: StyleParamType.Number;
};

const itemsStylesParams = createStylesParams<IItemsStylesParams>({
  itemBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // itemBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  itemBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
    }),
  },
  itemTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemPriceTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemImageRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});

type IVariantsStylesParams = {
  variantTitleTheme: StyleParamType.Font;
  variantTitleColor: StyleParamType.Color;
  variantPriceTheme: StyleParamType.Font;
  variantPriceColor: StyleParamType.Color;
};

const variantsStylesParams = createStylesParams<IVariantsStylesParams>({
  variantTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  variantTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  variantPriceTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  variantPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

type ILabelsStylesParams = {
  labelBackgroundColor: StyleParamType.Color;
  labelBorderColor: StyleParamType.Color;
  labelBorderWidth: StyleParamType.Number;
  labelBorderRadiusTopRight: StyleParamType.Number;
  labelBorderRadiusTopLeft: StyleParamType.Number;
  labelBorderRadiusBottomRight: StyleParamType.Number;
  labelBorderRadiusBottomLeft: StyleParamType.Number;
  labelTitleTheme: StyleParamType.Font;
  labelTitleColor: StyleParamType.Color;
  labelIconColor: StyleParamType.Color;
};

const labelsStylesParams = createStylesParams<ILabelsStylesParams>({
  labelBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  labelBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  labelTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

export type IDesignStylesParams = IMenusStylesParams &
  ISectionsStylesParams &
  IItemsStylesParams &
  IVariantsStylesParams &
  ILabelsStylesParams;

export const DesignStylesParams: StylesParamsType<IDesignStylesParams> = {
  ...menusStylesParams,
  ...sectionsStylesParams,
  ...itemsStylesParams,
  ...variantsStylesParams,
  ...labelsStylesParams,
};

import React from 'react';
import type { Label as LabelType } from 'root/apiTypes';
import { Label } from 'root/components/Menus/Label/Label';
import { classes } from './Labels.st.css';

export const Labels = ({ labels }: { labels: LabelType[] }) => {
  return (
    <div className={classes.labels}>
      {labels.map((label) => (
        <Label key={label.id} label={label} />
      ))}
    </div>
  );
};

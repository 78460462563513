import { StyleParamType, createStylesParams } from '@wix/tpa-settings';
import { Preset } from 'root/utils/presets';

export type ILayoutStylesParams = {
  preset: StyleParamType.Number;
  columns: StyleParamType.Number;
};

export const LayoutStylesParams = createStylesParams<ILayoutStylesParams>({
  preset: {
    type: StyleParamType.Number,
    getDefaultValue: () => Preset.Grid,
  },
  columns: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 3),
  },
});

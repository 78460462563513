import { useMemo, useState } from 'react';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn } from 'root/clientTypes';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';

export const useMenusLogic = ({ menus, navigateTo }: { menus: PopulatedMenu[]; navigateTo: NavigateToFn }) => {
  const menusItems = useMemo(() => menus.map((menu, index) => ({ title: menu?.name, id: index.toString() })), [menus]);
  const [activeTab, setActiveTab] = useState(0);
  const { showTabs } = useGetDisplayStylesParams();

  const shouldShowTabs = menus.length > 1 && showTabs;

  const onClickTab = async (index: number) => {
    const menuId = menus[index]?.id ?? '';
    const urlQueryParam = menus[index]?.urlQueryParam;
    setActiveTab(index);
    await navigateTo({ menuId, urlQueryParam });
  };

  return { activeTab, onClickTab, menusItems, shouldShowTabs };
};

import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { classes } from './Item.st.css';
import { useItemLogic } from './Item.logic';
import { Labels } from 'root/components/Menus/Labels/Labels';
import { PriceVariants } from 'root/components/Menus/PriceVariants/PriceVariants';
import { Text, Divider, WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';

export type ItemProps = {
  item: ShowcasePopulatedItem;
};

export const Item = ({ item }: ItemProps) => {
  const {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    showItemPrice,
    showItemLabels,
    showItemImage,
    showItemsDivider,
  } = useItemLogic({ item });

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer} data-hook={dataHooks.item.container}>
        <Text className={classes.itemName} data-hook={dataHooks.item.name}>
          {item.name}
        </Text>
        {showItemDescription && (
          <Text className={classes.itemDescription} data-hook={dataHooks.item.description}>
            {item.description}
          </Text>
        )}
        {showItemPrice &&
          (hasPriceVariants ? (
            <PriceVariants variants={item.priceVariants?.variants ?? []} />
          ) : showItemPrice ? (
            <Text className={classes.itemPrice} data-hook={dataHooks.item.price}>
              {priceContent}
            </Text>
          ) : undefined)}
        {item.labels && showItemLabels && <Labels data-hook={dataHooks.item.labels} labels={item.labels} />}
        {item.image && showItemImage && (
          <WowImage
            shouldUseLQIP
            alt={item.image?.altText ?? ''}
            aspectRatio="square"
            className={classes.itemImage}
            data-hook={dataHooks.item.image}
            resize={ImageResizeOptions.cover}
            sourceHeight={item.image?.height}
            sourceWidth={item.image?.width}
            src={item.image?.url ?? ''}
          />
        )}
        {showItemsDivider && <Divider className={classes.divider} data-hook={dataHooks.item.divider} />}
      </div>
    </div>
  );
};

import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';

export const useGetDisplayStylesParams = () => {
  const styles = useStyles();

  const showTabs = styles.get(stylesParams.showTabs);
  const showMenuName = styles.get(stylesParams.showMenuName);
  const showMenuDescription = styles.get(stylesParams.showMenuDescription);
  const showSectionName = styles.get(stylesParams.showSectionName);
  const showSectionDescription = styles.get(stylesParams.showSectionDescription);
  const showItemDescription = styles.get(stylesParams.showItemDescription);
  const showItemPrice = styles.get(stylesParams.showItemPrice);
  const showItemCurrency = styles.get(stylesParams.showItemCurrency);
  const showItemLabels = styles.get(stylesParams.showItemLabels);
  const showItemLabelsName = styles.get(stylesParams.showItemLabelsName);
  const showItemLabelsIcon = styles.get(stylesParams.showItemLabelsIcon);
  const showItemImage = styles.get(stylesParams.showItemImage);
  const showItemVariantsName = styles.get(stylesParams.showItemVariantsName);
  const showItemVariantsPrice = styles.get(stylesParams.showItemVariantsPrice);
  const showItemVariantsCurrency = styles.get(stylesParams.showItemVariantsCurrency);
  const showSectionsDivider = styles.get(stylesParams.showSectionsDivider);
  const showItemsDivider = styles.get(stylesParams.showItemsDivider);

  return {
    styles,
    showTabs,
    showMenuName,
    showMenuDescription,
    showSectionName,
    showSectionDescription,
    showItemDescription,
    showItemPrice,
    showItemCurrency,
    showItemLabels,
    showItemLabelsName,
    showItemLabelsIcon,
    showItemImage,
    showItemVariantsName,
    showItemVariantsPrice,
    showItemVariantsCurrency,
    showSectionsDivider,
    showItemsDivider,
  };
};

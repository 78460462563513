import React from 'react';
import type { PopulatedSection } from 'root/apiTypes';
import { st, classes } from './Section.st.css';
import { Items } from 'root/components/Menus/Items/Items';
import { Text, TextTypography, Divider } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { useSectionLogic } from './Section.logic';

export const Section = ({ section }: { section: PopulatedSection }) => {
  const { showSectionName, showSectionDescription, showSectionsDivider } = useSectionLogic();
  return (
    <div className={classes.sectionContainer} data-hook={dataHooks.section.container}>
      {showSectionName && (
        <Text
          className={classes.sectionTitle}
          data-hook={dataHooks.section.name}
          typography={TextTypography.smallTitle}
        >
          {section.name}
        </Text>
      )}
      {showSectionDescription && (
        <Text className={classes.sectionDescription} data-hook={dataHooks.section.description}>
          {section.description}
        </Text>
      )}
      <Divider className={st(classes.divider, classes.innerDivider)} />
      <Items items={section.items ?? []} />
      {showSectionsDivider && <Divider className={st(classes.divider, classes.bottomDivider)} />}
    </div>
  );
};

import { createContext } from 'react';
import type { PriceFormatterFn } from 'root/clientTypes';

type ContextType = {
  priceFormatter?: PriceFormatterFn;
};

export const AppContext = createContext<ContextType>({});

export const AppContextProvider = AppContext.Provider;

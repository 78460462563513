import React from 'react';
import { useAppWrapperLogic } from './AppWrapper.logic';
import { AppContextProvider } from 'root/contexts/AppContext';
import type { PriceFormatterFn } from 'root/clientTypes';
import { RtlProvider } from 'root/components/Menus/shared/RtlProvider/RtlProvider';

export const AppWrapper = ({
  children,
  priceFormatter,
}: {
  children: React.ReactNode;
  priceFormatter: PriceFormatterFn;
}) => {
  const { presetName } = useAppWrapperLogic();

  return (
    <AppContextProvider value={{ priceFormatter }}>
      <RtlProvider>
        <div className={`preset ${presetName}`}>{children}</div>
      </RtlProvider>
    </AppContextProvider>
  );
};

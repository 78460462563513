export enum Preset {
  Columns = 0,
  Grid = 1,
}

export const presets = [
  { id: Preset.Columns, value: 'Columns' },
  { id: Preset.Grid, value: 'Grid' },
];

export const getPresetName = (preset: Preset) => {
  switch (preset) {
    case Preset.Grid:
      return 'grid';
    case Preset.Columns:
      return 'columns';
    default:
      return '';
  }
};

export const presetToSettingsMap = (preset: Preset): string[] => {
  switch (preset) {
    case Preset.Columns:
      return ['columns'];
    case Preset.Grid:
      return ['columns'];
    default:
      return [];
  }
};

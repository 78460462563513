import { StyleParamType, createStylesParams } from '@wix/tpa-settings';

export type IDisplayStylesParams = {
  showTabs: StyleParamType.Boolean;
  showMenuName: StyleParamType.Boolean;
  showMenuDescription: StyleParamType.Boolean;
  showSectionName: StyleParamType.Boolean;
  showSectionDescription: StyleParamType.Boolean;
  showItemDescription: StyleParamType.Boolean;
  showItemPrice: StyleParamType.Boolean;
  showItemCurrency: StyleParamType.Boolean;
  showItemLabels: StyleParamType.Boolean;
  showItemImage: StyleParamType.Boolean;
  showItemLabelsName: StyleParamType.Boolean;
  showItemLabelsIcon: StyleParamType.Boolean;
  showItemVariantsName: StyleParamType.Boolean;
  showItemVariantsPrice: StyleParamType.Boolean;
  showItemVariantsCurrency: StyleParamType.Boolean;
  showSectionsDivider: StyleParamType.Boolean;
  showItemsDivider: StyleParamType.Boolean;
};

export const DisplayStylesParams = createStylesParams<IDisplayStylesParams>({
  showTabs: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile }) => (isMobile ? false : true),
  },
  showMenuName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showMenuDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showSectionName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showSectionDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemCurrency: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemLabels: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemLabelsName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemLabelsIcon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemVariantsName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemVariantsPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemVariantsCurrency: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showSectionsDivider: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showItemsDivider: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
});

export const dataHooks = {
  menus: {
    container: 'menus.container',
  },
  menu: {
    container: 'menu.container',
    name: 'menu.name',
    description: 'menu.description',
  },
  section: {
    container: 'section.container',
    name: 'section.name',
    description: 'section.description',
  },
  items: {
    container: 'items.container',
  },
  item: {
    container: 'item.container',
    name: 'item.name',
    description: 'item.description',
    divider: 'item.divider',
    labels: 'item.labels',
    label: 'item.label',
    image: 'item.image',
    price: 'item.price',
    priceVariants: 'item.priceVariants',
    variant: 'item.variant',
  },
  variant: {
    name: 'variant.name',
    price: 'variant.price',
  },
  settings: {
    layoutTab: 'settings.layout-tab',
    manageTab: 'settings.manage-tab',
    designTab: 'settings.design-tab',
    displayTab: 'settings.display-tab',
  },
  manageTab: {
    container: 'settings.manage-tab.container',
    emptyState: 'settings.manage-tab.empty-state',
    manageMenusButton: 'settings.manage-tab.manage-menus-button',
    addMenuPageButton: 'settings.manage-tab.add-menu-page-button',
  },
};

export const getDataHook = (dataHook: string) => `[data-hook="${dataHook}"]`;

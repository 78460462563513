import { Text } from 'wix-ui-tpa/cssVars';
import React from 'react';
import type { PriceVariant } from 'root/apiTypes';
import { useVariantLogic } from './Variant.logic';
import { classes } from './Variant.st.css';
import { dataHooks } from 'root/dataHooks';

export const Variant = ({ variant }: { variant: PriceVariant }) => {
  const { formattedPrice, showItemVariantsName, showItemVariantsPrice } = useVariantLogic({
    price: variant.priceInfo.price,
  });

  return (
    <div className={classes.variant} data-hook={dataHooks.item.variant}>
      {showItemVariantsName && (
        <Text className={classes.variantName} data-hook={dataHooks.variant.name}>
          {variant.name}
        </Text>
      )}
      {showItemVariantsPrice && (
        <Text className={classes.variantPrice} data-hook={dataHooks.variant.price}>
          {formattedPrice}
        </Text>
      )}
    </div>
  );
};

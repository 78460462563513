import { useContext } from 'react';
import { AppContext } from 'root/contexts/AppContext';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';

export const useVariantLogic = ({ price }: { price: string }) => {
  const { priceFormatter } = useContext(AppContext);
  const { showItemVariantsName, showItemVariantsPrice, showItemVariantsCurrency } = useGetDisplayStylesParams();

  const formattedPrice = priceFormatter
    ? priceFormatter({ price, shouldDisplayCurrency: showItemVariantsCurrency })
    : price;

  return { formattedPrice, showItemVariantsName, showItemVariantsPrice };
};
